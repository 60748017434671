import { isClientSide } from "@utils"

/*
  UX Utilities
*/
export const smoothScrollToElement = (elementId: string): void => {
  if (!isClientSide()) return
  const element = document.getElementById(elementId)
  if (!element) return
  const { top: elementTop } = element.getBoundingClientRect()
  window.scrollTo({
    top: elementTop + window.scrollY - 120, // "SiteNav height"
    behavior: 'smooth',
  })
  window.history.pushState("", "", `#${elementId}`)
}
